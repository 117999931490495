// /component/path/Path.js
import * as THREE from 'three';
import { Node } from './node/Node';
import _, { indexOf, last } from 'lodash';

class Path {
    constructor(scene) {
        this.nodes = [];

        const nodeProperties = [
            {
                isCheckpoint: true,
                labelPosition: new THREE.Vector3(0, 0, 0),
                labelTitle: 'Who are we?',
                labelMessage:
                    "Metropolice is a game company based in Klang Valley, Malaysia. Since our establishment in 2020, we've been focusing to produce a city building simulation to the global market. Our goal is to create great games that not just as many people as possible play for years and but also a game that make us better thinking human overall.",
                containerStyles: {},
                titleStyles: {},
                messageStyles: {},
            },
            {
                isCheckpoint: true,
                labelPosition: new THREE.Vector3(0, -0.75, 0),
                labelTitle: 'Why we build game?',
                labelMessage:
                    'Metropolice was founded on the precept that games are a great material to tell a story. We wanted to tell a story about all the worlds that fascinating our human mind. One in particular is the origin of hell.',
                containerStyles: {},
                titleStyles: {},
                messageStyles: {},
            },
            {
                isCheckpoint: true,
                labelPosition: new THREE.Vector3(0, -0.5, 0),
                labelTitle: 'How we started?',
                labelMessage:
                    'Our company is young but our heart is an old soul. Many of us came from the line of software development for e-commerce and business-to-business solution. Yet we are fearless to take on new coding software and embark on game design from web design. We are that leap of faith that people often asked many to take.',
                containerStyles: {},
                titleStyles: {},
                messageStyles: {},
            },
            {
                isCheckpoint: true,
                labelPosition: new THREE.Vector3(0, -0.5, 0),
                labelTitle: 'How we hire?',
                labelMessage:
                    'Best talent doesn’t always translate to be the best person to work with. As much as we admired talented personnel, it will always be the best talent whom everyone can work with and raise everyone game is what we truly adored. There is no hero in our team.',
                containerStyles: {},
                titleStyles: {},
                messageStyles: {},
            },
            {
                isCheckpoint: true,
                labelPosition: new THREE.Vector3(0, -0.5, 0),
                labelTitle: 'What is our plan?',
                labelMessage:
                    'Build the game. Make the money. Achieve greatness. \n\n Team members are encouraged to propose game idea and if it’s fun and sound. The Team members can collectively setup their own team and start cranking it up. All would be financed by each stakeholder in the company.',
                containerStyles: {},
                titleStyles: {},
                messageStyles: {},
            },
            {
                isCheckpoint: true,
                labelPosition: new THREE.Vector3(0, -0.5, 0),
                labelTitle: '',
                labelMessage:
                    'Thanks for flying with METROPOLICE. \n\n Send us an email if you would like to know more about us!',
                containerStyles: {},
                titleStyles: {},
                messageStyles: {},
            },
        ];

        let checkpointIndex = [0, 2, 4, 6, 7, 9, 10];
        let propertyIndex = 0;
        let nodeCounter = 0;
        _.clone(scene.children).forEach((child, index) => {
            if (!child.name.includes('Arrow')) {
                return;
            }

            if (nodeCounter === 0) {
                child.position.y = 175;
            }

            propertyIndex = indexOf(checkpointIndex, nodeCounter);
            this.addNode({
                position: child.position.clone(),
                targetQuaternion: child.quaternion.clone(),
                isCheckpoint: checkpointIndex.includes(nodeCounter),
                labelPosition: new THREE.Vector3(0, -0.5, 0),
                labelTitle: nodeProperties[propertyIndex]
                    ? nodeProperties[propertyIndex].labelTitle
                    : '',
                labelMessage: nodeProperties[propertyIndex]
                    ? nodeProperties[propertyIndex].labelMessage
                    : '',
                containerStyles: nodeProperties[propertyIndex]
                    ? nodeProperties[propertyIndex].containerStyles
                    : '',
                titleStyles: nodeProperties[propertyIndex]
                    ? nodeProperties[propertyIndex].titleStyles
                    : '',
                messageStyles: nodeProperties[propertyIndex]
                    ? nodeProperties[propertyIndex].messageStyles
                    : '',
            });
            nodeCounter++;

            child.removeFromParent();
        });

        const lastNode = this.nodes[this.nodes.length - 1];
        this.makeSceneResetNode({
            position: new THREE.Vector3(100, -15, 100).add(lastNode.position),
            targetQuaternion: new THREE.Quaternion(0, 0, 0, 0),
            isCheckpoint: true,
            labelPosition: new THREE.Vector3(-10, 0, 0),
            labelTitle: 'We have reached the end of our journey',
            containerStyles: {
                'font-family': 'MetropolisFont',
            },
            titleStyles: {
                color: 'white',
                'font-weight': 600,
                'font-size': '28px',
                'margin-bottom': '36px',
                'text-align': 'center',
            },
            messageStyles: {
                color: 'white',
                'font-weight': 600,
                'font-size': '20px',
                'line-height': 'normal',
            },
        });
        this.curve;
        this.drawLines();

        this.isVisible = true;
        this.updateVisibility();
    }

    toggleVisibility() {
        this.isVisible = !this.isVisible;
    }

    show() {
        this.isVisible = true;
    }

    hide() {
        this.isVisible = false;
    }

    updateVisibility() {
        this.nodes.map((node) => (node.mesh.visible = this.isVisible));
        this.line.material.visible = this.isVisible;
    }

    updatePath(nodes) {
        nodes.map((attribute, index) => {
            this.nodes[index].position = attribute.position;
            this.nodes[index].isCheckpoint = attribute.isCheckpoint;
        });

        this.drawLines();
        this.updateVisibility();
    }

    addNode(attributes, addAtIndex) {
        const node = new Node(attributes);

        if (addAtIndex) {
            this.nodes.splice(addAtIndex, 0, node);
        } else {
            this.nodes.push(node);
        }

        return node;
    }

    updateNode(index, attributes) {
        this.nodes[index].update(attributes);

        this.drawLines();
        this.updateVisibility();
    }

    makeSceneResetNode(attributes) {
        const lastNode = new Node(attributes);
        lastNode.isResetNode = true;

        const resetButtonElement = document.createElement('button');
        resetButtonElement.textContent = 'Fly Again';
        resetButtonElement.className = 'reset-button';
        resetButtonElement.style.backgroundColor = '#ffffff';
        resetButtonElement.style.width = '100%';
        resetButtonElement.style.padding = '25px 20px';
        resetButtonElement.style.fontSize = '18px';
        resetButtonElement.style.fontWeight = '900';
        resetButtonElement.style.borderRadius = '50px';
        resetButtonElement.style.whiteSpace = 'pre-line';
        resetButtonElement.addEventListener('mouseover', () => {
            resetButtonElement.style.backgroundColor = '#ff0000';
            resetButtonElement.style.cursor = 'pointer';
        });

        lastNode.container.appendChild(resetButtonElement);

        this.nodes.push(lastNode);
    }

    updateNodesLabelVisibility(viewerPosition) {
        _.forEach(this.nodes, (node) => {
            node.updateLabelOpacity(viewerPosition);
        });
    }

    updateNodesScroller(viewerPosition) {
        _.forEach(this.nodes, (node) => {
            node.updateScrollerVisuals(viewerPosition);
        });
    }

    drawLines() {
        const curve = new THREE.CatmullRomCurve3(this.nodes.map((node) => node.position));
        curve.curveType = 'centripetal';
        curve.closed = false;
        this.curve = curve;

        const points = curve.getPoints(this.nodes.length - 1);

        let line;
        if (this.line instanceof THREE.Line) {
            line = this.line;
            line.geometry.setFromPoints(points);
        } else {
            line = new THREE.Line(
                new THREE.BufferGeometry().setFromPoints(points),
                new THREE.LineBasicMaterial({ color: 0x00ff00 }),
            );
        }

        this.nodes.map((node) => {
            node.mesh.position.copy(node.position);
            line.add(node.mesh);
        });

        this.line = line;
    }

    getPointAt(number) {
        return this.curve.getPointAt(number);
    }
}

export { Path };
